<template>
  <v-card class="mt-4 mx-auto">
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="success"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-card
        dark
        color="color_green"
      >
        <v-card-title> DOMAIN </v-card-title>
      </v-card>
    </v-sheet>
    <v-card-text class="pt-0">
      <v-data-table
        id="domain-table"
        :headers="domain.headers"
        :items="domain.domains"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="color_green"
              dark
              @click="newDomainButtonEvent"
            >
              New domain
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.domain_description`]="{ item }">
          <span
            class="d-inline-block text-truncate"
            style="max-width: 30em"
          >
            {{ item.domain_description }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="pencilButtonEvent(item)"
          >
            mdi-pencil
          </v-icon>
          <delete
            :id="item.name"
            @deleted="delItem(item)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary">No data to show you</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },

  data() {
    return {
      search: '',
      loading: false
    };
  },

  computed: {
    ...mapState(['domain'])
  },

  created() {
    this.loading = true;
    this.storeDomains();
    this.loading = false;
  },

  methods: {
    ...mapActions(['storeDomains', 'deleteTableDomain', 'storeCustomerCRMID']),
    newDomainButtonEvent() {
      this.$emit('newDomainEvent');
    },
    pencilButtonEvent(item) {
      this.$emit('pencilEvent', item);
      this.storeCustomerCRMID(item);
    },
    delItem(item) {
      this.$emit('closeDialogEvent');
      this.deleteTableDomain(item.domain_id);
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
